import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from "@mui/material";

const AlternativesComparisonTable = ({ headers, data, isDetailed }) => (
    <TableContainer component={Paper} className="comparison-table">
        <Table aria-label="comparison table">
            <TableBody>
                {/* Render the headers dynamically */}
                <TableRow>
                    {headers.map((header, index) => (
                        <TableCell key={index}>
                            <Typography variant="h6">{header}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
                {/* Render the data dynamically */}
                {data.map((alt, index) => (
                    <TableRow key={index}>
                        <TableCell><Typography variant="body1">{alt.tool}</Typography></TableCell>
                        {isDetailed ? (
                            <>
                                <TableCell><Typography variant="body1">{alt.aiAgent}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.liveChat}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.aiCopilot}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.omnichannel}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.multilingual}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.freeTrial}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.startingPrice}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.rating}</Typography></TableCell>
                            </>
                        ) : (
                            <>
                                <TableCell>
                                    <Typography variant="body1">
                                        <ul>
                                            {alt.keyFeatures.split(", ").map((feature, i) => (
                                                <li key={i}>{feature}</li>
                                            ))}
                                        </ul>
                                    </Typography>
                                </TableCell>
                                <TableCell><Typography variant="body1">{alt.freePlan}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.startingPrice}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.useCase}</Typography></TableCell>
                                <TableCell><Typography variant="body1">{alt.rating}</Typography></TableCell>
                            </>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

export default AlternativesComparisonTable;