// --- Third Party Libraries ---
import React, { useEffect } from "react";
import { Box, Grid, Typography, Card, CardMedia, CardContent, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import { blogHeaderImages } from "../../utils/imageUtils";
import Header from "./Header";
import Footer from "./Footer";

// --- Slug Mapping ---
const blogSlugs = {
    1: "ai-sales",
    2: "ai-customer-service-2024",
    3: "chatgpt-vs-gemini",
    4: "intercom-alternatives",
    5: "best-customer-service-automation-software",
    6: "freshworks-alternative",
    7: "zendesk-alternative",
    8: "tidio-alternative",
    9: "helpcrunch-alternatives",
    10: "liveperson-competitors",
    11: "chatbot-alternatives"
  };

// --- BlogPage Component ---
export default function BlogPage() {

    // --- State and Hooks ---
    const navigate = useNavigate();
    const { t } = useTranslation();

    const imageMap = {
        header_img1: blogHeaderImages.header_img_article_1,
        header_img2: blogHeaderImages.header_img_article_2,
        header_img3: blogHeaderImages.header_img_article_3,
        header_img4: blogHeaderImages.header_img_article_4,
        header_img5: blogHeaderImages.header_img_article_5,
        header_img6: blogHeaderImages.header_img_article_6,
        header_img7: blogHeaderImages.header_img_article_7,
        header_img8: blogHeaderImages.header_img_article_8,
        header_img9: blogHeaderImages.header_img_article_9,
        header_img10: blogHeaderImages.header_img_article_10,
        header_img11: blogHeaderImages.header_img_article_11,
    };

    // Manually added articles
    const manualArticles = {
        4: {
            title: "12 Best Intercom Alternatives For Customer Support in 2024",
            subtitle: "Looking for Intercom alternatives to boost customer support? Here’s an overview of the top 12 picks to help you find the best one for your business.",
            date: "Oct 25, 2024 · 12 min read",
        },
        5: {
            title: "15 Best Customer Service Automation Software",
            subtitle: "Discover the 15 best customer service automation software solutions – features, pricing, integrations and ratings compared.",
            date: "Oct 28, 2024 · 17 min read",
        },
        6: {
            title: "Top Freshworks Alternatives: Our Picks for Customer Service Automation",
            subtitle: "Compare features, pricing options, user ratings and integrations of the 10 best Freshworks alternatives on the market to learn which suits your business.",
            date: "Oct 30, 2024 · 13 min read",
        },
        7: {
            title: "Zendesk Alternative: 8 Top Choices",
            subtitle: "What is the best Zendesk alternative? Here are our top eight picks with key features to help you find the perfect fit for your AI customer support.",
            date: "Dec 03, 2024 · 15 min read",
        },
        8: {
            title: "Intelswift: Top Tidio Alternative: What to Use?",
            subtitle: "Compare the features, pricing plans, user ratings and integration options of 5 similar tools to find the best Tidio alternative for your business.",
            date: "Dec 04, 2024 · 14 min read",
        },
        9: {
            title: "Top 10 HelpCrunch Alternatives Reviewed",
            subtitle: "See which of these HelpCrunch alternatives is best for your company – analyze the pricing, features, customer reviews and integrations of 10 solutions.",
            date: "Jan 02, 2025 · 16 min read",
        },
        10: {
            title: "6 Best LivePerson Competitors for More Efficient Customer Service",
            subtitle: "Looking for LivePerson alternatives? Here’s a detailed overview of the top six LivePerson competitors to help you choose the best one for your business.",
            date: "Jan 06, 2025 · 12 min read",
        },
        11: {
            title: "5 Best ChatBot Alternatives To Try in 2025",
            subtitle: "Discover the five best ChatBot alternatives. Explore AI-powered platforms that improve customer service and deliver personalized experiences.",
            date: "Jan 07, 2025 · 10 min read",
        },
    };

    // Merge articles from translation with manually added articles
    const articles = {
        ...t("blog.articles", { returnObjects: true }),
        ...manualArticles
    };

    // --- Event Handlers ---
    const handleCardClick = (id) => {
        const slug = blogSlugs[id];
        navigate(`/blog/${slug}`);
    };

    // Sort articles by descending order of their keys (id)
    const sortedArticles = Object.entries(articles).sort(([idA], [idB]) => Number(idB) - Number(idA));

    // --- useEffect Hooks ---
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // --- Render Component ---
    return (
        <>
            <Header />
            <Box className="blog-section">
                <Typography variant="h3" gutterBottom>{t("blog.blogPageTitle")}</Typography>
                <Grid container spacing={4}>
                    {sortedArticles.map(([id, article]) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={id}>
                            <Card className="blog-card" onClick={() => handleCardClick(id)}>
                                <CardMedia
                                    component="img"
                                    src={imageMap[`header_img${id}`]}
                                    alt={article.title}
                                />
                                <CardContent className="blog-content">
                                    <Chip label="ARTICLE" />
                                    <Typography variant="subtitle1">
                                        {article.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {article.subtitle}
                                    </Typography>
                                    <Typography variant="caption">
                                        {article.date}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Footer />
        </>
    );
}
