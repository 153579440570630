import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom'; 
import { ThemeProvider } from '@mui/material/styles';
import Routes from './Routes';
import { DefaultTheme } from "./common_css/defaultTheme";

function App() {
  useEffect(() => {
		const isProduction = window.location.hostname.includes("intelswift.com") && !window.location.hostname.includes("stage.intelswift.com");
		if(isProduction){
			ReactGA.initialize("G-8N5SMHJYGP");
		}
	}, [])
  return (
    <ThemeProvider theme={DefaultTheme}>
      <Router> 
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
