const calculateVolumePricing = (quantity) => {
    // Define pricing tiers
    const pricingTiers = [
      { min: 1, max: 2500, unitPrice: 0.07 },
      { min: 2501, max: 5000, unitPrice: 0.06 },
      { min: 5001, max: 8000, unitPrice: 0.055 },
      { min: 8001, max: 10000, unitPrice: 0.05 },
      { min: 10001, max: Infinity, unitPrice: 0.045 },
    ];
  
    // Base unit price for discount calculation
    const baseUnitPrice = 0.07;
  
    // Determine the appropriate unit price based on quantity
    let unitPrice = baseUnitPrice;
    for (const tier of pricingTiers) {
      if (quantity >= tier.min && quantity <= tier.max) {
        unitPrice = tier.unitPrice;
        break;
      }
    }
  
    // Calculate total amount and discount percentage
    const totalAmount = quantity * unitPrice;
    const discountPercent = ((baseUnitPrice - unitPrice) / baseUnitPrice) * 100;
  
    // Return the results
    return {
      unitPrice: unitPrice.toFixed(3), // Format to 3 decimal places
      discountPercent: discountPercent.toFixed(2), // Format to 2 decimal places
      totalAmount: totalAmount.toFixed(2), // Format to 2 decimal places
    };
  }

  export {
    calculateVolumePricing
}