import { createTheme } from "@mui/material/styles";

export const DefaultTheme = createTheme({
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: "contained" },
					style: {
						"border-radius": "8px",
						background: "#6257FF",
						"font-size": "12px",
						"font-style": "normal",
						boxShadow: 3,
					},
				},
				{
					props: { variant: "outlined", color: "primary" },
					style: {
						borderColor: "#6257FF",
						"border-radius": "8px",
						"font-size": "12px",
						"font-style": "normal",
						"color": "#6257FF"
					},
				},
				{
					props: { variant: "text", color: "primary" },
					style: {
						color: "#6257FF",
						"font-size": "12px",
						"font-style": "normal",
					},
				},
			],
		},
	},
});
